<template>
  <!-- 无对比图的标注 -->
  <div class="ContentBox">
    <div class="top">
      <Icon type="ios-arrow-back"
            style="position: absolute; left: 50px; font-size: 30px"
            @click="goBack" />
      <h1>{{ correlationData.name }}</h1>
      <!-- <h1 v-if="!edit1" @dblclick="editMsg1">{{ correlationData.name }}</h1>
      <Input
        v-if="edit1"
        v-model="correlationData.name"
        @on-enter="editMsgEnd1"
        @on-blur="editMsgEnd1"
        style="width: 600px"
      /> -->
    </div>
    <div class="Content">
      <!-- <div class="left">
        <div class="box">
          <div class="map previous"
               id="previous"></div>
           <h2>{{ isSWAT ? "卫星图" : "灾前卫星图" }}</h2> 
          <h2>卫星图</h2>
          <img :src="compass"
               alt="" />
        </div>
         <div class="text">
          <h4>
            制图单位 :
            {{
              isSWAT ? "长沙市公安局特巡警支队" : correlationData.chartingName
            }}
          </h4>
        </div> 
    </div> -->
      <div class="right">
        <div class="box">
          <div class="map upToDate"
               id="upToDate"></div>
          <img :src="compass"
               alt="" />
        </div>
        <div class="text">
          <h4>
            制图单位 :
            {{
              isSWAT ? "长沙市公安局特巡警支队" : correlationData.chartingName
            }}
          </h4>
          <h4>制图时间 : {{ correlationData.date }}</h4>
          <h4>
            <span>lng : {{ correlationData.lng }} ， lat :
              {{ correlationData.lat }}</span>
          </h4>
        </div>
      </div>
      <!-- 标注 -->
      <div class="tagging"
           v-if="true">
        <ul>
          <p>标注</p>
          <li>
            <Button type="primary"
                    @click="onMark">启用标注</Button>
          </li>
          <li @click="draw(1)"
              :class="{ active: 1 == current }">警员</li>
          <li @click="draw(2)"
              :class="{ active: 2 == current }">警车</li>
          <li @click="draw(3)"
              :class="{ active: 3 == current }">行动箭头</li>
          <li @click="draw(4)"
              :class="{ active: 4 == current }">持枪区域</li>
          <li @click="draw(5)"
              :class="{ active: 5 == current }">无人机区域</li>
          <li @click="draw(6)"
              :class="{ active: 6 == current }">警犬</li>
          <!-- <li @click="draw(7)"
              :class="{ active: 7 == current }">火势箭头</li> -->
          <li>
            <Button type="error"
                    @click="clearMark"
                    :disabled="!drawed">{{
              del == true ? "取消删除" : "删除标注"
            }}</Button>
          </li>
          <li>
            <Button type="warning"
                    @click="offMark"
                    :disabled="!drawed">取消标注</Button>
          </li>
          <p style="margin-top: 12px">测量</p>
          <li @click="rule('rule')">测距</li>
          <li @click="rule('measureArea')">测面积</li>
          <li>
            <Button type="warning"
                    @click="clearRule">清除测量</Button>
          </li>
          <li style="border: none; margin-top: 3px">
            <Button type="success"
                    @click="save"
                    :disabled="!drawed">保存标注</Button>
          </li>
        </ul>
      </div>
      <div v-else
           style="width: 50px"></div>
    </div>
    <Modal v-model="delModal"
           @on-ok="okDel">
      <p>确认删除该标注</p>
    </Modal>
    <!-- <i-button
      type="success"
      style="margin: 0 auto; display: block"
      @click="toScreen"
      >去标注</i-button
    > -->
  </div>
</template>

<script>
import compass from '@/assets/img/Icon/compass.png';
import Api from '@/utils/api.js';

export default {
  data() {
    return {
      id: 0,
      compass,
      map_2: null,
      correlationData: {
        name: '暂无标题',
        date: '0000-00-00',
        chartingName: '暂无制图单位',
        lng: '0',
        lat: '0',
        img: '',
      },
      // icon: null,
      edit1: false,
      edit2: false,
      mouseTool: null,
      ruleTool: null,
      drawing: false,
      overlays: {
        policeman: [], // 警员
        police_car: [], // 警车
        action: [], //  行动箭头
        gunArea: [], // 持枪区域
        uavArea: [], // 无人机区域
        dog: [], // 警犬
      }, // 鼠标绘制物
      markID: '',
      del: false,
      delModal: false,
      Distance: '',
      current: 0,
      drawed: true,
      isSWAT: false,
      ruled: true,
    };
  },

  mounted() {
    this.init_map_2();
    let routerParams = JSON.parse(sessionStorage.getItem('contrast'));
    // this.toLoadMapImg_2(routerParams);
    this.id = routerParams.id;
    this.getMarksDetail();
    let user_id = JSON.parse(sessionStorage.getItem('user_info')).user_id;
    if (user_id == 360) {
      this.isSWAT = true;
    }
  },
  methods: {
    init_map_2() {
      // 引入瓦片图-平面

      let _this = this;
      let layer = [];
      if (_this.$online) {
        layer = [new AMap.TileLayer()];
      } else {
        layer = [
          new AMap.TileLayer({
            getTileUrl: function (x, y, z) {
              return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
            },
            zIndex: 10,
          }),
        ];
      }
      //   this.map_2 = new AMap.Map("previous", {
      //     resizeEnable: true,
      //     expandZoomRange: true,
      //     zoom: 18, //地图显示的缩放级别
      //     center: [112.97935279, 28.21347823],
      //     zooms: [3, 19],
      //     // layers: [new AMap.TileLayer.Satellite()],
      //     layers: [new AMap.TileLayer({
      //       getTileUrl: function (x, y, z) {
      //         return (
      //                 Api.GaoDeStreetMap() +
      //                 z +
      //                 "/" +
      //                 x +
      //                 "/" +
      //                 y +
      //                 ".png"
      //         );
      //       },
      //       zIndex: 10,
      //     })]
      //   });

      //   // 修改主题样式
      //   // this.map_2.setMapStyle("amap://styles/whitesmoke");
      //   this.map_2.addControl(new AMap.Scale());

      this.map_2_upToDate = new AMap.Map('upToDate', {
        resizeEnable: true,
        expandZoomRange: true,
        zoom: 18, //地图显示的缩放级别
        zooms: [3, 20],
        center: this.$Center,
        // layers: [
        //   new AMap.TileLayer({
        //     getTileUrl: function (x, y, z) {
        //       return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
        //     },
        //     zIndex: 10,
        //   }),
        // ],
        layers: layer,
        // layers: [new AMap.TileLayer.Satellite()],
      });
      // 修改主题样式
      // this.map_2_upToDate.setMapStyle("amap://styles/whitesmoke");
      //添加比例尺
      this.map_2_upToDate.addControl(new AMap.Scale());
      //   this.map_2.addControl(new AMap.Scale());

      //同步操作
      //拖拽
      //   this.map_2.on("dragging", () => {
      //     var zoom = _this.map_2.getZoom();
      //     var location = _this.map_2.getCenter();
      //     _this.map_2_upToDate.setZoomAndCenter(zoom, [
      //       location.lng,
      //       location.lat,
      //     ]); //同时设置地图层级与中心点
      //   });
      //   this.map_2_upToDate.on("dragging", () => {
      //     var zoom = _this.map_2_upToDate.getZoom();
      //     var location = _this.map_2_upToDate.getCenter();
      //     _this.map_2.setZoomAndCenter(zoom, [location.lng, location.lat]); //同时设置地图层级与中心点
      //   });
      //   //放大缩小
      //   this.map_2.on("zoomchange", () => {
      //     var zoom = _this.map_2.getZoom();
      //     var location = _this.map_2.getCenter();
      //     _this.map_2_upToDate.setZoomAndCenter(zoom, [
      //       location.lng,
      //       location.lat,
      //     ]); //同时设置地图层级与中心点
      //   });
      //   this.map_2_upToDate.on("zoomchange", () => {
      //     var zoom = _this.map_2_upToDate.getZoom();
      //     var location = _this.map_2_upToDate.getCenter();
      //     _this.map_2.setZoomAndCenter(zoom, [location.lng, location.lat]); //同时设置地图层级与中心点
      //   });

      this.mouseTool = new AMap.MouseTool(this.map_2_upToDate); // 注册鼠标绘制工具
      this.ruleTool = new AMap.MouseTool(this.map_2_upToDate); // 注册鼠标测量工具

      this.mouseTool.on('draw', function () {
        _this.drawed = true;
      });

      this.ruleTool.on('draw', function () {
        _this.ruled = true;
      });

      this.map_2_upToDate.on('click', (e) => {
        this.correlationData.lng = e.lnglat.getLng();
        this.correlationData.lat = e.lnglat.getLat();
      });
    },

    toLoadMapImg_2(row) {
      console.log('row', row);
      // 拿到数据
      this.correlationData.name = row.mapname;
      this.correlationData.chartingName = row.address;
      this.correlationData.date = row.shooting_date;
      this.correlationData.img = row.path;
      let location = JSON.parse(row.angle_data);
      let lng =
        (Number(location.leftdown.lng) + Number(location.rightup.lng)) / 2;
      let lat =
        (Number(location.leftdown.lat) + Number(location.rightup.lat)) / 2;
      this.correlationData.lng = lng;
      this.correlationData.lat = lat;
      this.Distance = this.GetDistance(
        location.leftdown.lat,
        location.rightup.lng,
        location.rightup.lat,
        location.rightup.lng
      );
      //在地图加载图片
      //   this.map_2.clearMap();
      this.map_2_upToDate.clearMap();
      if (location) {
        this.imageLayer_2 = null;
        this.imageLayer_2 = new AMap.ImageLayer({
          bounds: new AMap.Bounds(
            [location.leftdown.lng, location.leftdown.lat],
            [location.rightup.lng, location.rightup.lat]
          ),
          url: row.path, // 图片 Url
          zooms: [3, 18], // 设置可见级别，[最小级别，最大级别]
          zIndex: 12,
        });

        // 将图层添加至地图实例
        this.map_2_upToDate.add(this.imageLayer_2);
        this.map_2_upToDate.setCenter([lng, lat]);
        // this.map_2.setCenter([Lng, Lat]);
      }
    },
    // 计算经纬度距离
    GetDistance(lat1, lng1, lat2, lng2) {
      var radLat1 = (lat1 * Math.PI) / 180.0;
      var radLat2 = (lat2 * Math.PI) / 180.0;
      var a = radLat1 - radLat2;
      var b = (lng1 * Math.PI) / 180.0 - (lng2 * Math.PI) / 180.0;
      var s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10;
      return s;
    },

    // 获取标注
    getMarksDetail() {
      this.$post(Api.mapMarkDetail(), {
        map_id: this.id,
      }).then((res) => {
        if (res.code == 1) {
          if (res.data) {
            console.log(res.data.command);
            this.markID = res.data ? res.data.id : '';
            this.overlays.policeman = JSON.parse(res.data.fireman);
            this.overlays.police_car = JSON.parse(res.data.fire_engine);
            this.overlays.action = JSON.parse(res.data.action);
            this.overlays.gunArea = JSON.parse(res.data.disaster_area);
            this.overlays.uavArea = JSON.parse(res.data.disaster_areas);
            this.overlays.dog = JSON.parse(res.data.command);
            this.overlays.disaster = JSON.parse(res.data.disaster);
            this.initMark();
          }
        }
      });
    },
    /**
     * 初始化标注
     */
    initMark() {
      let _this = this;
      //   console.log(this.overlays.policeman[0].p.O);
      // 警员
      this.overlays.policeman.forEach((ele) => {
        let policeman = new AMap.Marker({
          position: _this.$online ? [ele.p.R, ele.p.Q] : [ele.p.O, ele.p.P], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: new AMap.Icon({
            size: new AMap.Size(30, 36), // 图标尺寸
            image: require('../../assets/img/Marker/警员.png'), // Icon的图像
            imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
          }),
          offset: new AMap.Pixel(-15, -36),
          extData: { type: 1, dateID: ele.i },
        });
        policeman.dateID = policeman.on('click', this.showInfoM);
        this.map_2_upToDate.add(policeman);
      });
      // 警车
      this.overlays.police_car.forEach((ele) => {
        let police_car = new AMap.Marker({
          position: _this.$online ? [ele.p.R, ele.p.Q] : [ele.p.O, ele.p.P], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: new AMap.Icon({
            size: new AMap.Size(30, 36), // 图标尺寸
            image: require('../../assets/img/Marker/警车.png'), // Icon的图像
            imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
          }),
          offset: new AMap.Pixel(-15, -36),
          extData: { type: 2, dateID: ele.i },
        });
        police_car.on('click', this.showInfoM);
        this.map_2_upToDate.add(police_car);
      });
      // 行动箭头
      this.overlays.action.forEach((ele) => {
        let path = [];
        ele.p.forEach((element) => {
          path.push(
            _this.$online ? [element.R, element.Q] : [element.O, element.P]
          );
        });
        let action = new AMap.Polyline({
          //   path: ele.p,
          path: path,
          strokeColor: '#0084CA',
          strokeWeight: 10,
          lineJoin: 'round',
          lineCap: 'round',
          showDir: true,
          extData: { type: 3, dateID: ele.i },
        });
        action.on('click', this.showInfoM);
        this.map_2_upToDate.add(action);
      });
      // 持枪点
      this.overlays.gunArea.forEach((ele) => {
        let gunArea = new AMap.Marker({
          position: _this.$online ? [ele.p.R, ele.p.Q] : [ele.p.O, ele.p.P], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: new AMap.Icon({
            size: new AMap.Size(30, 36), // 图标尺寸
            image: require('../../assets/img/Marker/持枪区域.png'), // Icon的图像
            imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
          }),
          offset: new AMap.Pixel(-15, -36),
          extData: { type: 4, dateID: ele.i },
        });
        gunArea.on('click', this.showInfoM);
        this.map_2_upToDate.add(gunArea);
      });
      // 无人机点
      this.overlays.uavArea.forEach((ele) => {
        let uavArea = new AMap.Marker({
          position: _this.$online ? [ele.p.R, ele.p.Q] : [ele.p.O, ele.p.P], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: new AMap.Icon({
            size: new AMap.Size(30, 36), // 图标尺寸
            image: require('../../assets/img/Marker/无人机区域.png'), // Icon的图像
            imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
          }),
          offset: new AMap.Pixel(-15, -36),
          extData: { type: 5, dateID: ele.i },
        });
        uavArea.on('click', this.showInfoM);
        this.map_2_upToDate.add(uavArea);
      });
      // 警犬
      this.overlays.dog.forEach((ele) => {
        let dog = new AMap.Marker({
          position: _this.$online ? [ele.p.R, ele.p.Q] : [ele.p.O, ele.p.P], // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: new AMap.Icon({
            size: new AMap.Size(30, 36), // 图标尺寸
            image: require('../../assets/img/Marker/警犬.png'), // Icon的图像
            imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
          }),
          offset: new AMap.Pixel(-15, -36),
          extData: { type: 6, dateID: ele.i },
        });
        dog.on('click', this.showInfoM);
        _this.map_2_upToDate.add(dog);
        _this.map_2_upToDate.setFitView([dog]);
      });
      // 火势
      this.overlays.disaster.forEach((ele) => {
        let path = [];
        ele.p.forEach((element) => {
          path.push([element.R, element.Q]);
        });
        let disaster = new AMap.Polyline({
          path: path,
          strokeColor: '#f6212d',
          strokeWeight: 10,
          lineJoin: 'round',
          lineCap: 'round',
          showDir: true,
          extData: { type: 7, dateID: ele.i },
        });
        disaster.on('click', this.showInfoM);
        this.map_2_upToDate.add(disaster);
      });
    },
    /**
     * 绘制
     */
    draw(type) {
      if (this.drawing) {
        if (!this.drawed) {
          this.$Message.warning('请先完成当前标注');
          return;
        }
        this.current = type;
        this.ruleTool.close();
        let that = this;
        switch (type) {
          case 1: {
            // 警员
            that.mouseTool.marker({
              icon: new AMap.Icon({
                size: new AMap.Size(30, 36), // 图标尺寸
                image: require('../../assets/img/Marker/警员.png'), // Icon的图像
                imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -36),
              extData: { type: 1 },
            });
            break;
          }
          case 2: {
            // 警车
            that.mouseTool.marker({
              icon: new AMap.Icon({
                size: new AMap.Size(30, 36), // 图标尺寸
                image: require('../../assets/img/Marker/警车.png'), // Icon的图像
                imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -36),
              extData: { type: 2 },
            });
            break;
          }
          case 3: {
            this.drawed = false;
            //行动箭头
            that.mouseTool.polyline({
              strokeColor: '#0084CA',
              strokeWeight: 10, //线宽
              extData: { type: 3 },
            });
            break;
          }
          case 4: {
            // 持枪点
            that.mouseTool.marker({
              icon: new AMap.Icon({
                size: new AMap.Size(30, 36), // 图标尺寸
                image: require('../../assets/img/Marker/持枪区域.png'), // Icon的图像
                imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -36),
              extData: { type: 4 },
            });
            break;
          }
          case 5: {
            // 无人机
            that.mouseTool.marker({
              icon: new AMap.Icon({
                size: new AMap.Size(30, 36), // 图标尺寸
                image: require('../../assets/img/Marker/无人机区域.png'), // Icon的图像
                imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -36),
              extData: { type: 5 },
            });
            break;
          }
          case 6: {
            // 警犬
            that.mouseTool.marker({
              icon: new AMap.Icon({
                size: new AMap.Size(30, 36), // 图标尺寸
                image: require('../../assets/img/Marker/警犬.png'), // Icon的图像
                imageSize: new AMap.Size(30, 36), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -36),
              extData: { type: 6 },
            });
            break;
          }
          case 7: {
            this.drawed = false;
            // 火势箭头
            that.mouseTool.polyline({
              strokeWeight: 10,
              strokeColor: '#f6212d',
              extData: { type: 7 },
            });
            break;
          }
        }
      } else {
        this.$Message.info('请先启用标注功能');
      }
    },

    getDraw(e) {
      let date = new Date().getTime();
      let p = [];
      e.obj.on('click', this.showInfoM); // 监听覆盖物点击事件
      let that = this;
      switch (e.obj.getExtData().type) {
        case 1:
          e.obj.setExtData({ type: 1, dateID: date });
          that.overlays.policeman.push({
            p: e.obj.getPosition(),
            i: date,
          });
          break;
        case 2:
          e.obj.setExtData({ type: 2, dateID: date });
          that.overlays.police_car.push({ p: e.obj.getPosition(), i: date });
          break;
        case 3:
          // 重绘线段
          that.map_2_upToDate.remove(e.obj);
          let newAction = new AMap.Polyline({
            path: e.obj.getPath(),
            strokeColor: '#3CB371',
            strokeWeight: 10,
            lineJoin: 'round',
            lineCap: 'round',
            showDir: true,
            extData: { type: 3, dateID: date },
          });
          that.map_2_upToDate.add(newAction);
          newAction.on('click', this.showInfoM);
          that.overlays.action.push({ p: e.obj.getPath(), i: date });
          break;
        case 4:
          e.obj.setExtData({ type: 4, dateID: date });
          that.overlays.gunArea.push({ p: e.obj.getPosition(), i: date });
          break;
        case 5:
          e.obj.setExtData({ type: 5, dateID: date });
          that.overlays.uavArea.push({ p: e.obj.getPosition(), i: date });
          break;
        case 6:
          e.obj.setExtData({ type: 6, dateID: date });
          that.overlays.dog.push({ p: e.obj.getPosition(), i: date });
          break;
        case 7:
          // 重绘线段

          that.map_2_upToDate.remove(e.obj);
          let newDisaster = new AMap.Polyline({
            path: e.obj.getPath(),
            strokeColor: '#f6212d',
            strokeWeight: 10,
            lineJoin: 'round',
            lineCap: 'round',
            showDir: true,
            extData: { type: 7, dateID: date },
          });
          that.overlays.disaster.push({ p: e.obj.getPath(), i: date });
          newDisaster.on('click', this.showInfoM);
          that.map_2_upToDate.add(newDisaster);
          break;
        default:
          break;
      }
    },

    // 启用标注
    onMark() {
      // 测量完成前不可点击
      if (!this.ruled) {
        this.$Message.warning('请先结束当前测量');
        return;
      }
      this.$Message.info('选择标注物类型');
      this.del = false;
      this.drawing = true;
      //监听draw事件可获取画好的覆盖物
      this.mouseTool.on('draw', this.getDraw);
    },
    // 取消标注
    offMark() {
      if (!this.ruled) {
        this.$Message.warning('请先结束当前测量');
        return;
      }
      if (!this.drawed) {
        this.$Message.warning('请先完成当前标注');
        return;
      }
      this.current = 0;
      this.drawing = false;
      this.del = false;
      this.$Message.info('已关闭标注功能');
      // this.map_2_upToDate.off("click", this.clickHandler);
      this.mouseTool.close();
    },

    // 删除标注
    clearMark() {
      if (!this.ruled) {
        this.$Message.warning('请先结束当前测量');
        return;
      }
      if (!this.drawed) {
        this.$Message.warning('请先完成当前标注');
        return;
      }
      this.current = 0;
      this.mouseTool.close();
      this.drawing = false;
      this.del = !this.del;
    },
    // 选中需删除标注
    showInfoM(e) {
      if (this.del) {
        this.deleteObj = e.target;
        this.delModal = true;
      }
    },
    okDel() {
      let delID = this.deleteObj.getExtData().dateID;
      switch (this.deleteObj.getExtData().type) {
        case 1:
          this.overlays.policeman.forEach((e, index) => {
            if (e.i == delID) {
              this.overlays.policeman.splice(index, 1);
            }
          });
          break;
        case 2:
          this.overlays.police_car.forEach((e, index) => {
            if (e.i == delID) {
              this.overlays.police_car.splice(index, 1);
            }
          });
          break;
        case 3:
          this.overlays.action.forEach((e, index) => {
            if (e.i == delID) {
              this.overlays.action.splice(index, 1);
            }
          });
          break;
        case 4:
          this.overlays.gunArea.forEach((e, index) => {
            if (e.i == delID) {
              this.overlays.gunArea.splice(index, 1);
            }
          });
          break;
        case 5:
          this.overlays.uavArea.forEach((e, index) => {
            if (e.i == delID) {
              this.overlays.uavArea.splice(index, 1);
            }
          });
          break;
        case 6:
          this.overlays.dog.forEach((e, index) => {
            if (e.i == delID) {
              this.overlays.dog.splice(index, 1);
            }
          });
          break;
        case 7:
          this.overlays.disaster.forEach((e, index) => {
            if (e.i == delID) {
              this.overlays.disaster.splice(index, 1);
            }
          });
          break;
      }
      this.map_2_upToDate.remove(this.deleteObj); // 移除覆盖物
    },

    // 保存
    save() {
      if (!this.drawed) {
        this.$Message.warning('请先完成当前标注');
        return;
      }
      let params = {
        map_id: this.id,
        fireman: JSON.stringify(this.overlays.policeman),
        fire_engine: JSON.stringify(this.overlays.police_car),
        action: JSON.stringify(this.overlays.action),
        disasterArea: JSON.stringify(this.overlays.gunArea),
        DisasterAreas: JSON.stringify(this.overlays.uavArea),
        command: JSON.stringify(this.overlays.dog),
        disaster: JSON.stringify(this.overlays.disaster),
        mark_id: this.markID,
        mapname: this.correlationData.name,
      };
      this.$post(Api.mapMarkAdd(), params).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$Message.success(res.msg_customer);
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },

    // 测量
    rule(type) {
      // 绘图完成前不可测量
      if (!this.drawed) {
        this.$Message.warning('请先完成当前标注');
        return;
      }
      if (!this.drawed) {
        this.offMark();
      }
      this.ruled = false;
      let then = this;
      switch (type) {
        case 'rule': {
          then.ruleTool.rule({
            startMarkerOptions: {
              //可缺省
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: 'https://webapi.amap.com/theme/v1.3/markers/b/start.png',
              }),
            },
            endMarkerOptions: {
              //可缺省
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: 'https://webapi.amap.com/theme/v1.3/markers/b/end.png',
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            midMarkerOptions: {
              //可缺省
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: 'https://webapi.amap.com/theme/v1.3/markers/b/mid.png',
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            lineOptions: {
              //可缺省
              strokeStyle: 'solid',
              strokeColor: '#FF33FF',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
            //同 RangingTool 的 自定义 设置，缺省为默认样式
          });
          break;
        }
        case 'measureArea': {
          then.ruleTool.measureArea({
            strokeColor: '#80d8ff',
            fillColor: '#80d8ff',
            fillOpacity: 0.3,
            //同 Polygon 的 Option 设置
          });
          break;
        }
      }
    },
    // 清除测量
    clearRule() {
      this.ruleTool.close(true);
    },
    // 开启编辑
    editMsg1() {
      this.edit1 = true;
    },
    editMsg2() {
      this.edit2 = true;
    },
    //关闭编辑
    editMsgEnd1() {
      this.edit1 = false;
    },
    editMsgEnd2() {
      this.edit2 = false;
    },

    /**
     * 保存网页为图片
     */
    // saveCanvas() {
    //   html2canvas(document.body, {
    //     useCORS: true,
    //     foreignObjectRendering: true,
    //     allowTaint: false,
    //   }).then(function (canvas) {
    //     // document.body.appendChild(canvas);
    //     // let imgUri = canvas.toDataURL("image/png");
    //     // // .replace("image/png", "image/octet-stream");
    //     // console.log(imgUri);
    //     // $("#download").attr("href", imgUri);
    //     // document.getElementById("download").click();
    //   });
    // },
    goBack() {
      this.$router.back(-1);
    },
    // 跳转截图页面
    toScreen() {
      this.$router.push({
        name: '保存截图',
        params: {
          lng: this.correlationData.lng,
          lat: this.correlationData.lat,
          img: this.correlationData.img,
          Distance: this.Distance,
        },
      });
    },
  },
};
</script>

<style  scoped lang="scss">
.ContentBox {
  .top {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    h1 {
      cursor: pointer;
      display: block;
    }
  }
  .Content {
    width: 100%;
    height: 800px;
    display: flex;
    justify-content: space-between;
    // .left {
    //   width: 45%;
    //   margin-left: 40px;
    // }
    .right {
      width: calc(100% - 160px);
      margin-left: 40px;
    }
    .tagging {
      width: 100px;
      height: 650px;
      font-size: 14px;
      background-color: #f8f8f9;
      border: 1px solid #ccc;
      border-radius: 10px 0 0 10px;
      padding: 5px;
      text-align: center;
      p {
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        border-bottom: 2px solid #aaa;
      }
      li {
        list-style: none;
        border-bottom: 1px solid #ccc;
        vertical-align: middle;
        line-height: 40px;
        cursor: pointer;
        img {
          width: 20px;
          display: inline;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
    .box {
      width: 100%;
      height: 650px;
      margin-bottom: 20px;
      position: relative;
      .map,
      .img {
        width: 100%;
        height: 100%;
        background-color: #e6e6e6;
      }
      h2 {
        position: absolute;
        bottom: 10px;
        right: 15px;
        font-size: 24px;
        font-weight: bold;
        color: #3860ee;
        text-shadow: 1px 1px 0px #ffffff, -1px -1px 0px #ffffff,
          2px 2px 0px #ffffff, -2px -2px 0px #ffffff, 3px 3px 0px #ffffff,
          -3px -3px 0px #ffffff;
      }
      h3 {
        position: absolute;
        bottom: 10px;
        right: 15px;
        font-size: 24px;
        font-weight: bold;
        color: #ff021a;
        text-shadow: 1px 1px 0px #ffffff, -1px -1px 0px #ffffff,
          2px 2px 0px #ffffff, -2px -2px 0px #ffffff, 3px 3px 0px #ffffff,
          -3px -3px 0px #ffffff;
      }
      img {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    .text {
      width: 100%;
      height: 100px;
      /*padding: 10px 20px;*/
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font-size: 24px;
        span {
          font-size: 20px;
        }
      }
    }
  }
  .amap-scale-text {
    color: #fff;
  }
  .active {
    background-color: green;
    color: #fff;
  }
}
</style>
